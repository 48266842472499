import React from "react";

import "./Login.scss";
import { Flex, Button } from "@aws-amplify/ui-react";

export interface Credentials {
  email: string;
  password: string;
}

export interface LoginProps {
  onAuthLogin: () => void;
}

export const Login = (props: LoginProps) => {
  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="logo-wrapper"></div>
        <span className="page-title">Facility safety authentication</span>

        <div className="login-form">
          <Flex className="vertical-padded">
            <Button
              variation="primary"
              isFullWidth
              size="large"
              backgroundColor="#8CC63F"
              onClick={() => props.onAuthLogin()}
            >
              Sign In
            </Button>
          </Flex>
        </div>
      </div>

      <div className="security-message">
        <p>
          Access to this system is for authorized users only. Any unauthorized
          entry or attempt to enter is strictly forbidden and will result in
          prosecution to the maximum extent allowable by applicable law
        </p>

        <p>
          If you are a member of a Local Safety Council and need an account on
          the Partnership For Safety Data portal, please contact your Air
          Traffic Manager or NATCA Facility rep and he/she will initiate the
          process to generate an account for you. You may also contact the
          national program office via email (
          <a href="mailto:9-aji-ask-pfs@faa.gov" className="link">
            9-aji-ask-pfs@faa.gov
          </a>
          ) for further assistance. Include your name, email address, and
          affiliation and we will assist you.
        </p>

        <p>
          For security reasons, please Log Out and Exit your web browser when
          you are done accessing services that require authentication!
        </p>

        <p>
          For technical support, or help using this system (including password
          resets), please email Facility Safety Member Support at&nbsp;
          <a href="mailto:PFSusersupport@cssiinc.com" className="link">
            PFSusersupport@cssiinc.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default Login;
