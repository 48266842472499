import React from "react";
import Modal from "react-modal";

// Set the app element for the accessinility
Modal.setAppElement("#root");

const CopyrightModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Policy"
      style={{
        content: {
          top: "50%",
          left: "50%",
          width: "80%",
          right: "auto",
          maxHeight: "70vh",
          overflow: "hidden",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: 0,
        },
      }}
    >
      <div className="modal-header">Privacy policy</div>
      <div className="modal-content">
        <h3>PFS Privacy Policy</h3>
        <h5>Our Commitment</h5>

        <p>
          We respect your right to privacy and will protect it when you visit
          our website. This Privacy Policy explains our online information
          practices only, including how we collect and use your personal
          information. It does not apply to third-party websites that you are
          able to reach from &nbsp;
          <span className="bold">app.pfsportal.org</span>.
        </p>

        <h5>What We Automatically Collect Online</h5>
        <p>
          We collect information about your visit that does not identify you
          personally. We can tell the computer, browser, and web service you are
          using. We also know the date, time, and pages you visit. Collecting
          this information helps us design the site to suit your needs. In the
          event of a known security or virus threat, we may collect information
          on the web content you view.
        </p>

        <h5>Other Information We May Collect</h5>
        <p>
          When you visit our website, we may request and collect the following
          categories of personal information from you:
        </p>
        <ul>
          <li>Contact information</li>
          <li>Other official document information to verify your identity</li>
        </ul>

        <h5>Choices on How We Use the Information You Provide</h5>
        <p>
          Throughout our website, we will let you know whether the information
          we ask you to provide is voluntary or required. By providing
          personally identifiable information, you grant us consent to use this
          information, but only for the primary reason you are giving it. We
          will ask you to grant us consent before using your voluntarily
          provided information for any secondary purposes, other than those
          required under the law.
        </p>

        <h5>Cookies or Other Tracking Devices</h5>
        <p>
          A "cookie" is a small text file stored on your computer that makes it
          easy for you to move around a website without continually re-entering
          your name, password, preferences, for example.
        </p>
        <p>
          We only use "session" cookies on our website. This means we store the
          cookie on your computer only during your visit to our website. After
          you turn off your computer or stop using the Internet, the cookie
          disappears with your personal information.
        </p>

        <h5>Securing Your Information</h5>
        <p>
          Properly securing the information we collect online is a primary
          commitment. To help us do this, we take the following steps to:
        </p>
        <ul>
          <li>
            Employ internal access controls to ensure the only people who see
            your information are those with a need to do so to perform their
            official duties
          </li>
          <li>
            Train relevant personnel on our privacy and security measures to
            know requirements for compliance
          </li>
          <li>
            Secure the areas where we hold hard copies of information we collect
            online
          </li>
          <li>
            Perform regular backups of the information we collect online to
            insure against loss
          </li>
          <li>
            Use technical controls to secure the information we collect online
            including but not limited to:
            <ul>
              <li>Secure Socket Layer (SSL)</li>
              <li>Encryption</li>
              <li>Firewalls</li>
              <li>Password protections</li>
            </ul>
          </li>
          <li>
            We periodically test our security procedures to ensure personnel and
            technical compliance
          </li>
          <li>
            We employ external access safeguards to identify and prevent
            unauthorized tries of outsiders to hack into, or cause harm to, the
            information in our systems
          </li>
          <li>
            Tampering with this website is against the law. Depending on the
            offense, it is punishable under the Computer Fraud and Abuse Act of
            1986 and the National Information Infrastructure Protection Act.
          </li>
        </ul>

        <h5>Your Rights Under the Privacy Act of 1974</h5>
        <p>
          The Privacy Act of 1974 protects the personal information the federal
          government keeps on you in systems of records (SOR) (information an
          agency controls recovered by name or other personal identifier). The
          Privacy Act regulates how the government can disclose, share, provide
          access to, and keep the personal information that it collects. The
          Privacy Act does not cover all information collected online.
        </p>
        <p>The Act's major terms require agencies to:</p>
        <ul>
          <li>
            Publish a Privacy Act Notice in the Federal Register explaining the
            existence, character, and uses of a new or revised SOR
          </li>
          <li>
            Keep information about you accurate, relevant, timely, and complete
            to assure fairness in dealing with you
          </li>
          <li>
            Allow you to, on request, access and review your information held in
            an SOR and request amendment of the information if you disagree with
            it.
          </li>
        </ul>
        <p>
          When <span className="bold">app.pfsportal.org</span> collects
          information from you online that is subject to the Privacy Act
          (information kept in an SOR), we will provide a Privacy Act Statement
          specific to that collected information. This Privacy Act Statement
          tells you:
        </p>
        <ul>
          <li>
            The authority for and the purpose and use of the information
            collected subject to the Privacy Act
          </li>
          <li>Whether providing the information is voluntary or mandatory</li>
          <li>
            The effects on you if you do not provide any or all requested
            information.
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default CopyrightModal;
