import React, { useMemo, useState } from "react";

import "./AddUser.scss";
import { TabTypes, UserInfoData } from "../models/user";
import { UserInfoForm } from "./userInfoForm/userInfoForm.tsx";
import { UserGroupsForm } from "./userGroupsForm/userGroupsForm.tsx";
import { UserSiteForm } from "./userSitesForm/userSitesForm.tsx";
import { useAppDispatch, useTypedSelector } from "../../../store/app.store.tsx";
import { Group } from "../../../interfaces/auth.interface.tsx";
import { fetchRoles } from "../../../store/authentication.reducer.tsx";
import { PageLoader } from "../PageLoader/PageLoader.tsx";

export const AddUser = (props: { token: string }) => {
  const dispath = useAppDispatch();
  const { token } = props;

  const appGroups: Group[] = useTypedSelector(
    (state) => state.authentication.appGroups
  );
  const loadingRole = useTypedSelector(
    (state) => state.authentication.loadingRoles
  );
  const roleError = useTypedSelector((state) => state.authentication.roleError);
  const tabs: TabTypes[] = ["Info", "Groups", "Sites"];

  const [currentTab, setCurrentTab] = useState<TabTypes>("Info");
  const [userDetails, setUserDetails] = useState<UserInfoData | null>(null);
  const currentUser = useTypedSelector((state) => state.user.currentUser);

  useMemo(() => {
    if (loadingRole !== "DONE") {
      dispath(fetchRoles({ token }));
    }
  }, [dispath, loadingRole, token]);

  const onUserInfoSave = (data: UserInfoData): void => {
    setUserDetails(data);
    setCurrentTab("Groups");
  };

  if (loadingRole !== "DONE") {
    return (
      <div className="page">
        <PageLoader />
      </div>
    );
  }

  if (roleError) {
    return (
      <div className="page">
        <h3 className="error">{roleError}</h3>
      </div>
    );
  }

  return (
    <section className="page">
      <div className="tab-header">
        <section className="tabs">
          {tabs.map((tab: TabTypes, index: number) => (
            <div
              key={`tab-${index}`}
              className={`tab-item ${currentTab === tab ? "selected" : ""}`}
              onClick={() => {
                if (
                  tab === "Info" ||
                  (["Groups", "Sites"].includes(tab) &&
                    currentUser?.userInfo.user_id)
                ) {
                  setCurrentTab(tab);
                }
              }}
            >
              {tab}
            </div>
          ))}
        </section>
      </div>
      {currentTab === "Info" && (
        <UserInfoForm
          onUserInfoUpdated={onUserInfoSave}
          userDetails={userDetails}
          token={token}
        />
      )}
      {currentTab === "Groups" && (
        <UserGroupsForm
          token={token}
          groups={appGroups}
          backButtonHandler={() => setCurrentTab("Info")}
        />
      )}

      {currentTab === "Sites" && (
        <UserSiteForm
          token={token}
          user_id="user_id"
          allSites={appGroups}
          userSites={[]}
          backButtonHandler={() => setCurrentTab("Groups")}
        />
      )}
    </section>
  );
};
