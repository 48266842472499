import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export interface AirlineState {
  loading: "IDLE" | "PENDING" | "DONE" | "FAILED";
  error: string | null | undefined;
  airlines: string[];
}

export const fetchAirlines = createAsyncThunk(
  "fetchAirlines",
  async (payload: { token: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/airlines`,
      {
        method: "GET",
        headers: { Authorization: payload.token },
      }
    );
    return response.json();
  }
);

const AirlineSlice = createSlice({
  name: "airlines",
  initialState: {
    loading: "IDLE",
    error: null,
    airlines: [],
  } as AirlineState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAirlines.pending, (state) => {
        state.error = null;
        state.loading = "PENDING";
      })
      .addCase(fetchAirlines.fulfilled, (state, action) => {
        state.airlines = action.payload;
        state.loading = "DONE";
      })
      .addCase(fetchAirlines.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = "FAILED";
      });
  },
});

// export const {} = GroupSlice.actions;
export const reducer = AirlineSlice.reducer;
