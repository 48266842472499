import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import "./styles/styles.scss";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { Provider } from "react-redux";

import awsconfig from "./aws-exports";

import "@aws-amplify/ui-react/styles.css";
import { studioTheme } from "./ui-components";
import { store } from "./store/app.store.tsx";

Amplify.configure(awsconfig);

const container = document.getElementById("root");
const root = createRoot(container);

const handleRedirectCallback = (evt) => {
  console.log("redirect handler is", evt);
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={studioTheme}>
        <Auth0ProviderWithNavigate
          handleRedirectCallback={handleRedirectCallback}
        >
          <Provider store={store}>
            <App />
          </Provider>
        </Auth0ProviderWithNavigate>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
