import React from "react";

import "./PageLoader.scss";
import { Loader } from "@aws-amplify/ui-react";

export const PageLoader = (props: { message?: string | null }) => {
  return (
    <div className="page-loader">
      <Loader size="large" className="loader" />
      {props.message ?? "Loading..."}
    </div>
  );
};
