import React, { useEffect } from "react";
import "./TableauView.scss";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useTypedSelector } from "../../../store/app.store.tsx";
import { DashboardItem } from "../../../interfaces/dashboard.interface.tsx";
import { fetchTokens } from "../../../store/authentication.reducer.tsx";
import Tableau from "./Tableau.js";

export interface TableauViewProps {
  selectedFacility: string;
  apiEndpoint: string;
  email: string;
  token: string;
}

export interface Tokens {
  tableau_token: string | null;
  portal_token: string | null;
}

interface State {
  dashboard: DashboardItem;
}

export const TableauView = (props: TableauViewProps) => {
  const dispatch = useAppDispatch();
  const selectedFacility = useTypedSelector(
    (state) => state.facility.selectedFacility
  );
  const loadingToken = useTypedSelector(
    (state) => state.authentication.loading
  );
  const tableauToken = useTypedSelector(
    (state) => state.authentication.tableau_token
  );
  const error = useTypedSelector((state) => state.authentication.error);

  const location = useLocation();
  let state: State | null = null;
  const { email, token } = props;

  const options = {
    width: "100%",
    height: "calc(100vh - 160px)",
    device: "desktop",
    toolbar: "hidden",
  };

  if (
    location.state &&
    typeof location.state === "object" &&
    "dashboard" in location.state
  ) {
    state = location.state as State;
  }

  useEffect(() => {
    if (loadingToken === "IDLE") {
      dispatch(fetchTokens({ email, token }));
    }
  }, [dispatch, email, loadingToken, token]);

  if (loadingToken !== "DONE" || !state || !token) {
    return <h1>Loading</h1>;
  }

  if (error) {
    return <h3 className="error">{error}</h3>;
  }

  return (
    <section className="tableau-view">
      <div id="tableau-viz" />
      <Tableau
        url={state.dashboard.url}
        options={options}
        selectedFacility={selectedFacility}
        pfsToken={token}
        tableauToken={tableauToken}
      />
    </section>
  );
};
