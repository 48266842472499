import React, { useEffect } from "react";

import "./Dashboard.scss";
import { Image } from "@aws-amplify/ui-react";

import Tracon from "../../../images/Tracon.png";
import Artcc from "../../../images/Artcc.png";
import District from "../../../images/District.png";
import Oapm from "../../../images/Oapm.png";
import Overview from "../../../images/Overview.png";
import CISP from "../../../images/CISP.png";
import Default from "../../../images/Default.png";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useTypedSelector } from "../../../store/app.store.tsx";
import {
  fetchTopics,
  setSelectedDashboard,
} from "../../../store/dashboard.reducer.tsx";
import { PageLoader } from "../PageLoader/PageLoader.tsx";
import { fetchAirlines } from "../../../store/groups.reducer.tsx";

export interface Topic {
  name: string;
}

const ImageMap = {
  Tracon,
  Artcc,
  District,
  Oapm,
  Overview,
  CISP,
  Default,
};

export const TopicElement = (props: { topic: Topic }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { topic } = props;

  const getImage = (name: string): any => {
    if (Object.keys(ImageMap).includes(name)) {
      return ImageMap[name];
    }
    return ImageMap.Default;
  };

  const goToDetails = () => {
    const url = `/dashboard/${topic.name.replace("/", "-")}`;
    dispatch(setSelectedDashboard(topic.name));
    navigate(url);
  };
  return (
    <div className="topic" onClick={goToDetails}>
      <div className="logo">
        <Image src={getImage(topic.name)} alt={topic.name} />
      </div>
      <label>
        {topic.name.replace("Tracon", "TRACON").replace("Artcc", "ARTCC")}{" "}
        Topics
      </label>
    </div>
  );
};

export const Dashboard = (props: { email: string; token: string }) => {
  const dispatch = useAppDispatch();
  const loading = useTypedSelector((state) => state.dashboard.loading);
  const loadingAirlines = useTypedSelector((state) => state.airlines.loading);
  const error = useTypedSelector((state) => state.dashboard.error);
  const topics = useTypedSelector((state) => state.dashboard.topics);
  const { email, token } = props;

  useEffect(() => {
    if (loading === "IDLE") {
      dispatch(fetchTopics({ email, token }));
    }
    if (loadingAirlines === "IDLE") {
      dispatch(fetchAirlines({ token }));
    }
  }, [email, token, dispatch, loading, loadingAirlines]);

  if (loading !== "DONE") {
    return (
      <div className="dashboard">
        <PageLoader />
      </div>
    );
  }

  if (error) {
    return <h1>Error {error}</h1>;
  }

  return (
    <section className="dashboard">
      <section className="content">
        <div className="topic-container">
          {topics.map((topic: Topic) => (
            <TopicElement topic={topic} key={topic.name} />
          ))}
        </div>
        <p>
          Safety Topics show how the rest of select safety-related events have
          evolved over time. Rates are displayed for individual facilities if
          the amount of data allows, and otherwise for a group of facilities.
          Where appropriate, NAS-wide rates are also displayed as a benchmark
        </p>
      </section>
    </section>
  );
};
