import React from "react";
import "./TopHeader.scss";
import plane from "../../../images/Rectangle_7.png";
import logo2 from "../../../images/Rectangle_2.png";
import pfsLogo from "../../../images/pfs-logo.png";
import natcaLogo from "../../../images/natca.png";
import { Image } from "@aws-amplify/ui-react";

export interface TopHeaderProps {
  user: any;
}

export const TopHeader = (props: TopHeaderProps) => {
  const { user } = props;

  return (
    <div className="top-header">
      <div className="logos">
        <Image src={plane} alt="plane" />
        <h1>Safety Data Portal</h1>
        <Image src={logo2} alt="plane" />
        <Image src={pfsLogo} alt="plane" />
        <Image src={natcaLogo} alt="plane" />
      </div>

      <Image className="gravatar" src={user.picture} alt="gravatar" />
    </div>
  );
};
