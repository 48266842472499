import React, { useState } from "react";
import "./SideNav.scss";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Image } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";

export interface SideNavProps {
  label: string;
  url?: string;
  key: string;
  disabled?: boolean;
  roles?: string[];
  click?: () => void;
  subMenus: SideNavProps[];
}

const SideNavItem = (props: { menu: SideNavProps; userRoles: string[] }) => {
  const {
    menu: { label, subMenus, url, roles, click },
    userRoles,
  } = props;
  const hasChildren = subMenus && subMenus.length > 0;
  const [showSubNav, setShowSubNav] = useState<boolean>(false);

  const toggleSubNav = () => {
    setShowSubNav(!showSubNav);
  };

  const shouldRender = (): boolean => {
    if (!roles) return true;
    return roles.some((role) => userRoles.includes(role));
  };

  return !shouldRender() ? null : (
    <>
      <div
        className={`sidebar-item ${props.menu.disabled ? "disabled-link" : ""}`}
        onClick={hasChildren ? toggleSubNav : () => {}}
      >
        {url ? (
          <Link to={url} color="black">
            {label}
          </Link>
        ) : (
          <span onClick={click}>{label}</span>
        )}
        {hasChildren && (showSubNav ? <FaChevronUp /> : <FaChevronDown />)}
      </div>
      {showSubNav && hasChildren && (
        <ul className="sub-nav">
          {subMenus.map((m) => (
            <li key={m.key}>
              <Link to={m.url ?? ""}>{m.label}</Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export const SideNav = (props: {
  menus: SideNavProps[];
  user: any;
  userRoles: string[];
}) => {
  const { menus, user, userRoles } = props;

  return (
    <section className="side-nav-wrapper">
      <div className="welcome">welcome!</div>
      <section className="menus-container">
        <div className="menu-gravatar">
          <Image className="gravatar" src={user.picture} alt="gravatar" />
          <span className="name">{user.name}</span>
        </div>
        <div className="sidebar">
          {menus.map((menu) => (
            <SideNavItem
              menu={menu}
              key={`top-menu-${menu.key}`}
              userRoles={userRoles}
            />
          ))}
        </div>
      </section>
    </section>
  );
};
