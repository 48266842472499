import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import { Facility, FacilityItem, FacilityRole } from "../interfaces";

export interface FacilityState {
  selectedFacility: string | null;
  facilities: Facility[];
  loadingFacilities: boolean;
  facilityError: string | null;

  allFacilities: FacilityItem[];
  facilityRoles: FacilityRole[];
  fetchingFacilities: "IDLE" | "PENDING" | "DONE";
  errorFetchingFacilities: string | null;
}

export const fetchFacilities = createAsyncThunk(
  "data/fetchFacilities",
  async (payload: { email: string; token: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/facilities/${payload.email}`,
      { headers: { Authorization: payload.token } }
    );
    return response.json();
  }
);

export const fetchAllFacilities = createAsyncThunk(
  "fetchAllFacilities",
  async (payload: { token: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/facilities`,
      { headers: { Authorization: payload.token } }
    );
    return response.json();
  }
);

const facilitySlice = createSlice({
  name: "facility",
  initialState: {
    selectedFacility: null,
    facilities: [],
    loadingFacilities: false,
    facilityError: null,

    allFacilities: [],
    facilityRoles: [],
    fetchingFacilities: "IDLE",
    errorFetchingFacilities: null,
  } as FacilityState,
  reducers: {
    setSelectedFacility: (
      state: FacilityState,
      action: PayloadAction<string | null>
    ) => {
      state.selectedFacility = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllFacilities.pending, (state) => {
        state.fetchingFacilities = "PENDING";
        state.errorFetchingFacilities = null;
      })
      .addCase(fetchAllFacilities.rejected, (state, action) => {
        state.errorFetchingFacilities = action.error.message ?? null;
        state.fetchingFacilities = "DONE";
        state.facilities = [];
      })
      .addCase(fetchAllFacilities.fulfilled, (state, action) => {
        state.allFacilities = action.payload.facilities;
        state.facilityRoles = action.payload.facilities_roles;
        state.fetchingFacilities = "DONE";
      })
      .addCase(fetchFacilities.pending, (state) => {
        state.loadingFacilities = true;
        state.facilityError = null;
      })
      .addCase(fetchFacilities.rejected, (state, action) => {
        state.facilityError = action.error.message ?? null;
        state.loadingFacilities = false;
      })
      .addCase(fetchFacilities.fulfilled, (state, action) => {
        state.facilities = action.payload.code ? [] : action.payload;
        state.facilityError = action.payload.code
          ? action.payload.message
          : null;
        state.loadingFacilities = false;

        if (!state.selectedFacility && action.payload.length > 0) {
          state.selectedFacility = action.payload[0].site;
        }
      });
  },
});

export const reducer = facilitySlice.reducer;
export const { setSelectedFacility } = facilitySlice.actions;
