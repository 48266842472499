import React, { useEffect, useMemo, useState } from "react";
import { UserInfoData } from "../../models/user.tsx";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

import "./userInfoForm.scss";
import {
  useAppDispatch,
  useTypedSelector,
} from "../../../../store/app.store.tsx";
import {
  createUserInfo,
  setCurrentUser,
} from "../../../../store/user.reducer.tsx";
import { UserInfo } from "../../../../interfaces/user.interface.tsx";
import { fetchRoles } from "../../../../store/authentication.reducer.tsx";
import { Role } from "../../../../interfaces/auth.interface.tsx";

export const UserInfoForm = (props: {
  onUserInfoUpdated: (data: UserInfoData) => void;
  userDetails?: UserInfoData | null;
  token: string;
}) => {
  const { token } = props;
  const dispatch = useAppDispatch();
  const loadingRole = useTypedSelector(
    (state) => state.authentication.loadingRoles
  );
  const error = useTypedSelector((state) => state.user.errorSubmitting);
  const currentUser = useTypedSelector((state) => state.user.currentUser);
  const submitting = useTypedSelector((state) => state.user.submitting);
  const roles = useTypedSelector((state) => state.authentication.appRoles);
  const [formErrors, setFormErrors] = useState<any>({});

  useMemo(() => {
    if (loadingRole !== "DONE") {
      dispatch(fetchRoles({ token }));
    }
  }, [dispatch, loadingRole, token]);

  useEffect(() => {
    if (currentUser) {
      const { userInfo } = currentUser;
      setFormErrors(validateFormData(userInfo));
    }
  }, [currentUser]);

  const [isValid, setIsValid] = useState<boolean>(false);

  const validateFormData = (values: UserInfo | null) => {
    const errors: any = {};

    if (!values) return errors;
    if (!values.first_name) {
      errors.first_name = "Required";
    }

    if (!values.email_address) {
      errors.email_address = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email_address)
    ) {
      errors.email_address = "Invalid email address";
    }

    if (!values.last_name) {
      errors.last_name = "Required";
    }
    setIsValid(Object.keys(errors).length === 0);
    return errors;
  };

  const onSubmit = () => {
    if (currentUser?.userInfo) {
      const values = { ...currentUser.userInfo } as any;
      values.role_id = parseInt(values.role_id);
      values.active = values.active ? 1 : 0;
      console.log("submitting", values);
      dispatch(createUserInfo({ data: values, token }));
    }
  };

  return (
    <form className="user-info-form">
      {error && <div className="form-error">{error}</div>}
      <FormControl>
        <TextField
          disabled={submitting}
          id="first_name"
          label="First Name"
          value={currentUser?.userInfo?.first_name ?? ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
              setCurrentUser(
                currentUser
                  ? {
                      userInfo: {
                        ...currentUser.userInfo,
                        first_name: event.target.value,
                      },
                    }
                  : {
                      userInfo: {
                        first_name: event.target.value,
                      },
                    }
              )
            );
          }}
        />
        {formErrors && formErrors.first_name && (
          <FormHelperText className="error">
            {formErrors.first_name}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl>
        <TextField
          disabled={submitting}
          id="middle_name"
          label="Middle Name"
          value={currentUser?.userInfo?.middle_name ?? ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
              setCurrentUser(
                currentUser
                  ? {
                      userInfo: {
                        ...currentUser.userInfo,
                        middle_name: event.target.value,
                      },
                    }
                  : {
                      userInfo: {
                        middle_name: event.target.value,
                      },
                    }
              )
            );
          }}
        />
        {formErrors && formErrors.middle_name && (
          <FormHelperText className="error">
            {formErrors.middle_name}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl>
        <TextField
          disabled={submitting}
          id="last_name"
          label="Last Name"
          value={currentUser?.userInfo?.last_name ?? ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
              setCurrentUser(
                currentUser
                  ? {
                      userInfo: {
                        ...currentUser.userInfo,
                        last_name: event.target.value,
                      },
                    }
                  : {
                      userInfo: {
                        last_name: event.target.value,
                      },
                    }
              )
            );
          }}
        />
        {formErrors && formErrors.last_name && (
          <FormHelperText className="error">
            {formErrors.last_name}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl>
        <TextField
          disabled={submitting}
          id="last_name"
          label="Email Address"
          value={currentUser?.userInfo?.email_address ?? ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
              setCurrentUser(
                currentUser
                  ? {
                      userInfo: {
                        ...currentUser.userInfo,
                        email_address: event.target.value,
                      },
                    }
                  : {
                      userInfo: {
                        email_address: event.target.value,
                      },
                    }
              )
            );
          }}
        />
        {formErrors && formErrors.email_address && (
          <FormHelperText className="error">
            {formErrors.email_address}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="role">Role</InputLabel>
        <Select
          disabled={submitting}
          labelId="role-label"
          id="role"
          value={currentUser?.userInfo?.role_id}
          label="Role"
          onChange={(event: SelectChangeEvent) => {
            dispatch(
              setCurrentUser(
                currentUser
                  ? {
                      userInfo: {
                        ...currentUser.userInfo,
                        role_id: event.target.value,
                      },
                    }
                  : {
                      userInfo: {
                        role_id: event.target.value,
                      },
                    }
              )
            );
          }}
        >
          {roles.map((role: Role) => (
            <MenuItem key={role.role_id} value={role.role_id}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            disabled={submitting}
            defaultChecked
            size="large"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              console.log("checkbox", event.target.checked);
              dispatch(
                setCurrentUser(
                  currentUser
                    ? {
                        userInfo: {
                          ...currentUser.userInfo,
                          is_active: event.target.value,
                        },
                      }
                    : {
                        userInfo: {
                          is_active: event.target.value,
                        },
                      }
                )
              );
            }}
          />
        }
        label="Is Active"
      />
      <div className="button-container">
        <Button
          variant="contained"
          disabled={!isValid || submitting}
          onClick={onSubmit}
        >
          Create User
        </Button>
      </div>
    </form>
  );

  // return (
  //   <Formik
  //     initialValues={initialValues}
  //     validate={validateFormData}
  //     onSubmit={onSubmit}
  //   >
  //     {({ isSubmitting }) => (
  //       <Form>
  //         {error && <div className="error-message">{error}</div>}
  //         <div className="field">
  //           <label htmlFor="first_name">First Name</label>
  //           <Field type="text" name="first_name" />
  //           <ErrorMessage name="first_name" component="div" className="error" />
  //         </div>
  //         <div className="field">
  //           <label htmlFor="middle_name">Middle Name</label>
  //           <Field type="text" name="middle_name" />
  //           <ErrorMessage
  //             name="middle_name"
  //             component="div"
  //             className="error"
  //           />
  //         </div>
  //         <div className="field">
  //           <label htmlFor="last_name">Last Name</label>
  //           <Field type="text" name="last_name" />
  //           <ErrorMessage name="last_name" component="div" className="error" />
  //         </div>
  //         <div className="field">
  //           <label htmlFor="email_address">Email Address</label>
  //           <Field type="text" name="email_address" />
  //           <ErrorMessage
  //             name="email_address"
  //             component="div"
  //             className="error"
  //           />
  //         </div>

  //         <div className="field">
  //           <label htmlFor="email_address">Application Role</label>
  //           <Field as="select" name="role_id">
  //             {roles.map((role: Role) => (
  //               <option key={role.role_id} value={role.role_id}>
  //                 {role.name}
  //               </option>
  //             ))}
  //           </Field>
  //           <ErrorMessage name="role_id" component="div" className="error" />
  //         </div>

  //         <div className="checkbox">
  //           <label>
  //             <Field
  //               type="checkbox"
  //               name="active"
  //               label="Is Active"
  //               className="checkbox"
  //             />
  //             Is Active
  //           </label>
  //         </div>
  //         <Button
  //           variant="primary"
  //           type="submit"
  //           size="lg"
  //           disabled={isSubmitting || !isValid}
  //         >
  //           Save Info
  //         </Button>
  //       </Form>
  //     )}
  //   </Formik>
  // );
};
