import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import { Topic, DashboardItem } from "../interfaces/dashboard.interface";

export interface DashbaordState {
  topics: Topic[];
  loading: "IDLE" | "LOADING" | "DONE";
  error: string | null;
  selectedDashboard: string | null;
  loadingDetails: "IDLE" | "LOADING" | "DONE";
  dashboardDetails: DashboardItem[];
  dashboardDetailsError: string | null;
}

export const fetchTopics = createAsyncThunk(
  "fetchTopics",
  async (payload: { email: string; token: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/facility-types/${payload.email}`,
      { headers: { Authorization: payload.token } }
    );
    return response.json();
  }
);

export const fetchDashboardDetails = createAsyncThunk(
  "fetchDashboardDetails",
  async (payload: { email: string; token: string; topic: string }) => {
    const queryParams = new URLSearchParams({
      topic: payload.topic.replace("-", "/"),
    });
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/facility-types/${payload.email}/dashboards?${queryParams}`,
      { headers: { Authorization: payload.token } }
    );
    return response.json();
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    topics: [],
    loading: "IDLE",
    error: null,
    selectedDashboard: null,
    loadingDetails: "IDLE",
    dashboardDetails: [],
    dashboardDetailsError: null,
  } as DashbaordState,
  reducers: {
    setSelectedDashboard: (
      state: DashbaordState,
      action: PayloadAction<string>
    ) => {
      state.selectedDashboard = action.payload;
    },
    clearDashboardDetails: (state) => {
      state.dashboardDetails = [];
      state.loadingDetails = "IDLE";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopics.pending, (state) => {
        state.loading = "LOADING";
        state.error = null;
      })
      .addCase(fetchTopics.rejected, (state, action) => {
        state.loading = "DONE";
        state.error = action.error.message ?? null;
      })
      .addCase(fetchTopics.fulfilled, (state, action) => {
        state.loading = "DONE";
        state.error = null;
        state.topics = action.payload.filter(
          (topic) => !["district", "cisp"].includes(topic.name.toLowerCase())
        );
      })
      .addCase(fetchDashboardDetails.pending, (state) => {
        state.loadingDetails = "LOADING";
        state.dashboardDetailsError = null;
      })
      .addCase(fetchDashboardDetails.rejected, (state, action) => {
        state.loadingDetails = "DONE";
        state.dashboardDetailsError = action.error.message ?? null;
      })
      .addCase(fetchDashboardDetails.fulfilled, (state, action) => {
        state.loadingDetails = "DONE";
        state.dashboardDetailsError = null;
        state.dashboardDetails = action.payload.code
          ? []
          : action.payload.map((item) => {
              return {
                ...item,
                isAvailable: !item.url.endsWith(
                  process.env.REACT_APP_TABLEAU_BASE_URL as string
                ),
              };
            });
      });
  },
});

export const reducer = dashboardSlice.reducer;
export const { setSelectedDashboard, clearDashboardDetails } =
  dashboardSlice.actions;
