import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInfo } from "../interfaces/user.interface";

export interface FormState {
  user: {
    userInfo: {
      data: UserInfo | null;
      valid: boolean;
    };
  };
}

const formsSlice = createSlice({
  name: "forms",
  initialState: {
    user: {
      userInfo: {
        data: null,
        valid: false,
      },
    },
  } as FormState,
  reducers: {
    setUserInfoFormValidity: (
      state: FormState,
      action: PayloadAction<boolean>
    ) => {
      state.user.userInfo.valid = action.payload;
    },
    clearUserInfoData: (state: FormState) => {
      state.user.userInfo = {
        valid: false,
        data: null,
      };
    },
  },
});

export const reducer = formsSlice.reducer;
export const { setUserInfoFormValidity, clearUserInfoData } =
  formsSlice.actions;
