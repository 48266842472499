import React, { useEffect, useRef, useState } from "react";
import Table from "react-bootstrap/Table";

import "./userSitesForm.scss";
import { TableHeader } from "../../models/user";
import {
  useAppDispatch,
  useTypedSelector,
} from "../../../../store/app.store.tsx";
import { fetchAllFacilities } from "../../../../store/facilities.reducer.tsx";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  FacilityItem,
  FacilityRole,
} from "../../../../interfaces/facility.interface.tsx";
import {
  DeleteUserSite,
  UpdateUserSite,
  UserSite,
  fetchUserSites,
} from "../../../../store/user.reducer.tsx";
import { PageLoader } from "../../PageLoader/PageLoader.tsx";

const AddSiteForm = (props: {
  facilities: FacilityItem[];
  roles: FacilityRole[];
  token: string;
  user_id: string | undefined;
  cancel: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { facilities, roles, token, user_id, cancel } = props;
  const [siteToAdd, setSiteToAdd] = useState({ site_id: "", role_id: "" });
  const currentUserSites = useTypedSelector(
    (state) => state.user.currentUser.userSites
  );
  const handleSiteChange = (event) => {
    setSiteToAdd({
      ...siteToAdd,
      site_id: event.target.value,
    });
  };

  const handleRoleChange = (event) => {
    setSiteToAdd({
      ...siteToAdd,
      role_id: event.target.value,
    });
  };

  const addSite = () => {
    if (user_id) {
      dispatch(
        UpdateUserSite({
          facility_id: parseInt(siteToAdd.site_id),
          role_id: parseInt(siteToAdd.role_id),
          token: token,
          user_id,
        })
      );
      cancel();
    }
  };

  return (
    <form className="add-site">
      <div className="flex">
        <FormControl fullWidth>
          <InputLabel id="site">Site</InputLabel>
          <Select
            labelId="site"
            id="demo-simple-select"
            label="Site"
            value={siteToAdd.site_id}
            onChange={handleSiteChange}
          >
            {facilities
              .filter((facility) => {
                const siteIds = currentUserSites.map((site) => site.site_id);
                const names = currentUserSites.map((site) => site.site);

                return (
                  !siteIds.includes(facility.facility_id) ||
                  !names.includes(facility.name)
                );
              })
              .map((facility) => (
                <MenuItem
                  key={`option-${facility.facility_id}`}
                  value={facility.facility_id}
                >
                  {facility.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="role">Role</InputLabel>
          <Select
            labelId="role"
            id="demo-simple-select"
            label="Role"
            onChange={handleRoleChange}
            value={siteToAdd.role_id}
          >
            {roles
              .filter((role) => role.role_id)
              .map((role) => (
                <MenuItem key={`role-${role.role_id}`} value={role.role_id}>
                  {role.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>

      <div className="add-site">
        <Button variant="outlined" onClick={() => cancel()}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => addSite()}>
          Save
        </Button>
      </div>
    </form>
  );
};

export const UserSiteForm = (props: {
  token: string;
  user_id: string;
  allSites: any[];
  userSites: any[];
  backButtonHandler: () => void;
}) => {
  const { token } = props;
  const newSiteRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const currentUser = useTypedSelector((state) => state.user.currentUser);
  const facilities = useTypedSelector((state) => state.facility.allFacilities);
  const roles = useTypedSelector((state) => state.facility.facilityRoles);
  const fetchingFacilities = useTypedSelector(
    (state) => state.facility.fetchingFacilities
  );
  const fetchingUserSites = useTypedSelector(
    (state) => state.user.loadingUserSites
  );
  const [addSite, setAddSite] = useState(false);

  useEffect(() => {
    if (fetchingFacilities === "IDLE") {
      dispatch(fetchAllFacilities({ token }));
    }
    if (currentUser.userInfo.user_id) {
      dispatch(
        fetchUserSites({ token, user_id: currentUser.userInfo.user_id })
      );
    }
  }, [currentUser.userInfo.user_id, dispatch, fetchingFacilities, token]);

  const headers: TableHeader[] = [
    { key: "group_name", friendly: "Group" },
    { key: "site", friendly: "Site" },
    { key: "role", friendly: "Role" },
    { key: "action", friendly: "Action" },
  ];

  const deleteSite = (site: UserSite) => {
    if (currentUser.userInfo.user_id) {
      dispatch(
        DeleteUserSite({
          token,
          user_id: currentUser.userInfo.user_id,
          facility_id: site.site_id,
          role_id: site.role_id,
        })
      );
    }
  };

  const sendRegistrationEmail = () => {
    console.log("send registration email");
  };

  return (
    <section className="sites">
      <div className="headings">
        <h3>Sites</h3>
        <button
          className="button-link"
          onClick={() => {
            setAddSite(true);
            newSiteRef.current?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Add Site
        </button>
      </div>

      {fetchingUserSites !== "DONE" && <PageLoader />}

      {fetchingUserSites === "DONE" && (
        <Table striped bordered hover>
          <thead>
            <tr>
              {headers.map((header: TableHeader) => (
                <th key={header.key}>{header.friendly}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentUser.userSites.map((site: any, index: number) => {
              return (
                <tr key={`row-${index}`}>
                  {headers.map((header: TableHeader, index1: number) => {
                    if (header.key === "action") {
                      return (
                        <td key={`col-${header.key}-${index}-${index1}`}>
                          <IconButton
                            aria-label="delete"
                            size="large"
                            onClick={() => deleteSite(site)}
                          >
                            <DeleteIcon fontSize="large" />
                          </IconButton>
                        </td>
                      );
                    }

                    return (
                      <td key={`col-${header.key}-${index}-${index1}`}>
                        {site[header.key]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {addSite && (
        <AddSiteForm
          facilities={facilities}
          roles={roles}
          token={token}
          user_id={currentUser.userInfo.user_id}
          cancel={() => setAddSite(false)}
        />
      )}

      <div className="group-btn">
        <Button
          variant="outlined"
          size="large"
          onClick={props.backButtonHandler}
        >
          Back to Groups
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={() => sendRegistrationEmail()}
        >
          Send Registration Email
        </Button>
      </div>
      <div ref={newSiteRef}></div>
    </section>
  );
};
