import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import "./AutoLogout.scss";

const AutoLogout = ({ timeout }) => {
  const { logout } = useAuth0();
  const [remainingTime, setRemainingTime] = useState(timeout);

  useEffect(() => {
    let timer;
    let countdownInterval;

    const resetTimer = () => {
      if (timer) clearTimeout(timer);
      if (countdownInterval) clearInterval(countdownInterval);

      setRemainingTime(timeout);

      timer = setTimeout(() => {
        logout({
          logoutParams: {
            returnTo: process.env.REACT_APP_URL_REDIRECT,
          },
        });
      }, timeout);

      countdownInterval = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1000) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevTime - 1000;
        });
      }, 1000);
    };

    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    resetTimer();

    return () => {
      if (timer) clearTimeout(timer);
      if (countdownInterval) clearInterval(countdownInterval);
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, [logout, timeout]);

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div
      className={`auto-logout ${remainingTime < 1000 * 60 * 5 ? "danger" : ""}`}
    >
      Auto logout in: {formatTime(remainingTime)}
    </div>
  );
};

export default AutoLogout;
