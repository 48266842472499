import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { User, UserInfo } from "../interfaces/user.interface";

export interface UserSite {
  email_address: string;
  user_id: number;
  role: string;
  role_id: number;
  site: string;
  site_id: number;
  group_name: string;
  group_id: number;
}

export interface UserState {
  submitting: boolean;
  errorSubmitting: string | null;
  currentUser: {
    userInfo: UserInfo;
    userGroups: string[];
    userSites: UserSite[];
  };
  fetchingUser: "IDLE" | "DONE" | "FAILED" | "PENDING";
  users: User[];
  errorFetchingUser: string | null;
  fetchingUserGroups: "IDLE" | "DONE" | "FAILED" | "PENDING";
  errorFetchingUserGroup: string | null;
  updatingGroup: boolean;
  errorUpdatingGroup: string | null;
  loadingUserSites: "IDLE" | "DONE" | "FAILED" | "PENDING";
  filters: {
    searchTerm: string;
    searchBy: string;
  };
}

export const createUserInfo = createAsyncThunk(
  "createUserInfo",
  async (payload: { data: UserInfo; token: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/users`,
      {
        method: "POST",
        body: JSON.stringify(payload.data),
        headers: { Authorization: payload.token },
      }
    );
    return response.json();
  }
);

export const fetchUsers = createAsyncThunk(
  "fetchUsers",
  async (payload: { token: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/users`,
      {
        method: "GET",
        headers: { Authorization: payload.token },
      }
    );
    return response.json();
  }
);

export const fetchUserGroups = createAsyncThunk(
  "fetchUserGroups",
  async (payload: { token: string; user_id: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/users/${payload.user_id}/groups`,
      {
        method: "GET",
        headers: { Authorization: payload.token },
      }
    );
    return response.json();
  }
);

export const UpdateUserGroups = createAsyncThunk(
  "UpdateUserGroups",
  async (payload: { token: string; user_id: string; groups }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/users/${payload.user_id}/groups`,
      {
        method: "PUT",
        headers: { Authorization: payload.token },
        body: JSON.stringify({ groups: payload.groups }),
      }
    );
    return response.json();
  }
);

export const fetchUserFacilities = createAsyncThunk(
  "fetchUserFacilities",
  async (payload: { token: string; user_id: string; groups }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/users/${payload.user_id}/facilities`,
      {
        method: "GET",
        headers: { Authorization: payload.token },
      }
    );
    return response.json();
  }
);

export const fetchUserSites = createAsyncThunk(
  "fetchUserSites",
  async (payload: { token: string; user_id: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/users/${payload.user_id}/sites`,
      {
        method: "GET",
        headers: { Authorization: payload.token },
      }
    );
    return response.json();
  }
);

export const UpdateUserSite = createAsyncThunk(
  "UpdateUserSite",
  async (payload: {
    token: string;
    user_id: string;
    role_id: number;
    facility_id: number;
  }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/users/${payload.user_id}/sites`,
      {
        method: "PUT",
        headers: { Authorization: payload.token },
        body: JSON.stringify({
          facility_id: payload.facility_id,
          role_id: payload.role_id,
        }),
      }
    );
    return response.json();
  }
);

export const DeleteUserSite = createAsyncThunk(
  "DeleteUserSite",
  async (payload: {
    token: string;
    user_id: string;
    role_id: number;
    facility_id: number;
  }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/users/${payload.user_id}/sites`,
      {
        method: "DELETE",
        headers: { Authorization: payload.token },
        body: JSON.stringify({
          facility_id: payload.facility_id,
          role_id: payload.role_id,
        }),
      }
    );
    return response.json();
  }
);

const UserSlice = createSlice({
  name: "user",
  initialState: {
    submitting: false,
    errorSubmitting: null,
    currentUser: {
      userInfo: {
        first_name: "",
        last_name: "",
        middle_name: "",
        active: true,
        email_address: "",
        role_id: "10165",
      },
      userGroups: [],
      userSites: [],
    },
    users: [],
    fetchingUser: "IDLE",
    errorFetchingUser: null,
    fetchingUserGroups: "IDLE",
    errorFetchingUserGroup: null,
    updatingGroup: false,
    errorUpdatingGroup: null,
    loadingUserSites: "IDLE",
    filters: {
      searchBy: "first_name",
      searchTerm: "",
    },
  } as UserState,
  reducers: {
    clearCurrentUser: (state) => {
      state.loadingUserSites = "IDLE";
      state.fetchingUserGroups = "IDLE";
      state.currentUser = {
        userInfo: {
          first_name: "",
          last_name: "",
          middle_name: "",
          active: true,
          email_address: "",
          role_id: "10165",
        },
        userGroups: [],
        userSites: [],
      };
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setUserGroup: (state, action) => {
      state.currentUser.userGroups = action.payload;
    },
    setFilterSearchTerm: (state, action) => {
      state.filters.searchTerm = action.payload;
    },
    setFilterSearchBy: (state, action) => {
      state.filters.searchBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UpdateUserSite.pending, (state) => {
        state.loadingUserSites = "PENDING";
      })
      .addCase(UpdateUserSite.rejected, (state, action) => {
        state.loadingUserSites = "FAILED";
      })
      .addCase(UpdateUserSite.fulfilled, (state, action) => {
        state.currentUser.userSites = action.payload;
        state.loadingUserSites = "DONE";
      })
      .addCase(DeleteUserSite.pending, (state) => {
        state.loadingUserSites = "PENDING";
      })
      .addCase(DeleteUserSite.rejected, (state, action) => {
        state.loadingUserSites = "FAILED";
      })
      .addCase(DeleteUserSite.fulfilled, (state, action) => {
        state.currentUser.userSites = action.payload;
        state.loadingUserSites = "DONE";
      })
      .addCase(fetchUserSites.pending, (state) => {
        state.loadingUserSites = "PENDING";
      })
      .addCase(fetchUserSites.rejected, (state, action) => {
        state.loadingUserSites = "FAILED";
      })
      .addCase(fetchUserSites.fulfilled, (state, action) => {
        state.currentUser.userSites = action.payload;
        state.loadingUserSites = "DONE";
      })
      .addCase(UpdateUserGroups.pending, (state) => {
        state.updatingGroup = true;
        state.errorUpdatingGroup = null;
      })
      .addCase(UpdateUserGroups.rejected, (state, action) => {
        state.errorUpdatingGroup = action.error.message ?? null;
        state.updatingGroup = false;
      })
      .addCase(UpdateUserGroups.fulfilled, (state, action) => {
        state.currentUser.userGroups = action.payload.map(
          (group) => group.group_id
        );
        state.updatingGroup = false;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.fetchingUser = "PENDING";
        state.errorFetchingUser = null;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.errorFetchingUser = action.error.message ?? null;
        state.fetchingUser = "FAILED";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload.code ? [] : action.payload;
        state.fetchingUser = "DONE";
      })
      .addCase(fetchUserGroups.pending, (state) => {
        state.fetchingUserGroups = "PENDING";
        state.errorFetchingUserGroup = null;
      })
      .addCase(fetchUserGroups.rejected, (state, action) => {
        state.errorFetchingUser = action.error.message ?? null;
        state.fetchingUserGroups = "FAILED";
      })
      .addCase(fetchUserGroups.fulfilled, (state, action) => {
        state.currentUser.userGroups = action.payload.map(
          (group) => group.group_id
        );
        state.fetchingUserGroups = "DONE";
      })
      .addCase(createUserInfo.pending, (state) => {
        state.submitting = true;
        state.errorSubmitting = null;
      })
      .addCase(createUserInfo.rejected, (state, action) => {
        state.errorSubmitting = action.error.message ?? null;
        state.submitting = false;
      })
      .addCase(createUserInfo.fulfilled, (state, action) => {
        if (!action.payload.code) {
          state.currentUser = state.currentUser
            ? { ...state.currentUser, userInfo: action.payload }
            : { userInfo: action.payload, userGroups: [] };
        } else {
          state.errorSubmitting = action.payload.message;
        }
        state.submitting = false;
      });
  },
});
export const {
  setCurrentUser,
  setUserGroup,
  setFilterSearchBy,
  setFilterSearchTerm,
  clearCurrentUser,
} = UserSlice.actions;
export const reducer = UserSlice.reducer;
