/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { PageLoader } from "../PageLoader/PageLoader.tsx";

import "./UserManagement.scss";
import { useAppDispatch, useTypedSelector } from "../../../store/app.store.tsx";
import {
  clearCurrentUser,
  fetchUsers,
  setCurrentUser,
  setFilterSearchBy,
  setFilterSearchTerm,
} from "../../../store/user.reducer.tsx";
import { User, UserTableHeader } from "../../../interfaces/user.interface.tsx";
import {
  Button,
  SearchField,
  SelectField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import Modal from "react-bootstrap/Modal";
import { AddUser } from "../AddUser/AddUser.tsx";

export const UserManagement = (props: { token: string }) => {
  const dispath = useAppDispatch();
  const { token } = props;

  const loading = useTypedSelector((state) => state.user.fetchingUser);
  const error = useTypedSelector((state) => state.user.errorFetchingUser);
  const users = useTypedSelector((state) => state.user.users);
  const userTableHeaders: UserTableHeader[] = [
    { key: "first_name", label: "First Name" },
    { key: "middle_name", label: "Middle Name" },
    { key: "last_name", label: "Last Name" },
    { key: "email_address", label: "Email" },
    { key: "active", label: "Active" },
  ];
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (loading === "IDLE") {
      dispath(fetchUsers({ token }));
    }
  }, [dispath, loading, token]);

  const RenderUsers = (props: {
    users: User[];
    headers: UserTableHeader[];
    itemPerPages?: number;
  }) => {
    const { users, headers, itemPerPages = 10 } = props;
    const [numPages, setNumPages] = useState(
      Math.floor(users.length / itemPerPages)
    );
    const [filteredItems, setFilteredItems] = useState(users);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsToShow, setItemToShow] = useState(
      users.slice(currentPage, currentPage + itemPerPages)
    );
    const searchTerm = useTypedSelector(
      (state) => state.user.filters.searchTerm
    );
    const searchBy = useTypedSelector((state) => state.user.filters.searchBy);

    const RenderRow = (props: { user: User }) => {
      const { user } = props;

      const computeActiveStatus = (active: number) => {
        return active === 0 ? "No" : "Yes";
      };

      const viewUserDetails = (userDetails: User) => {
        dispath(
          setCurrentUser({
            userInfo: userDetails,
          })
        );
        setShowModal(true);
      };
      return (
        <TableRow onClick={() => viewUserDetails(user)}>
          {headers.map((header: UserTableHeader) => (
            <TableCell key={`user-${user.user_id}-${header.key}`} fontSize={16}>
              {header.key === "active"
                ? computeActiveStatus(user[header.key])
                : user[header.key]}
            </TableCell>
          ))}
        </TableRow>
      );
    };

    useEffect(() => {
      if (searchTerm.trim().length === 0) {
        setFilteredItems(users);
      } else {
        setFilteredItems(
          users.filter((user: User) => {
            return user[searchBy]
              .toLowerCase()
              .includes(searchTerm.toLowerCase().trim());
          })
        );
      }
    }, [searchBy, searchTerm, users]);

    useEffect(() => {
      setNumPages(Math.floor(filteredItems.length / itemPerPages));
    }, [filteredItems, itemPerPages]);

    useEffect(() => {
      setItemToShow(
        filteredItems.slice(currentPage, currentPage + itemPerPages)
      );
    }, [currentPage, filteredItems, itemPerPages, users]);

    return (
      <div className="table-container">
        <div className="filters">
          <SearchField
            className="search-terms"
            label=""
            placeholder="Search..."
            size="large"
            value={searchTerm}
            hasSearchButton
            onClear={() => dispath(setFilterSearchTerm(""))}
            onChange={(e) => dispath(setFilterSearchTerm(e.target.value))}
          />
          <SelectField
            className="search-by"
            size="large"
            label=""
            value={searchBy}
            onChange={(e) => dispath(setFilterSearchBy(e.target.value))}
          >
            <option value="email_address">Email</option>
            <option value="first_name">First Name</option>
            <option value="last_name">Last Name</option>
          </SelectField>
        </div>
        <Table highlightOnHover variation="striped" size="large">
          <TableHead>
            <TableRow>
              {headers.map((header: UserTableHeader) => (
                <TableCell as="th" key={header.key}>
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {itemsToShow.map((user: User, index: number) => (
              <RenderRow
                user={user}
                key={`user-row-${index}-${user.user_id}`}
              />
            ))}
          </TableBody>
        </Table>
        <div className="pagination">
          <span>Page</span>
          <SelectField
            label=""
            value={currentPage.toString()}
            onChange={(e) => setCurrentPage(parseInt(e.target.value))}
          >
            {Array.from({ length: numPages }, (_, i) => i + 1).map(
              (value: number) => (
                <option key={`page-${value}`} value={value}>
                  {value}
                </option>
              )
            )}
          </SelectField>
          <span>of {numPages}</span>
        </div>
      </div>
    );
  };

  const addUser = () => {
    setShowModal(true);
  };

  const closeAddUser = () => {
    dispath(clearCurrentUser());
    setShowModal(false);
  };

  return (
    <div className="user-management">
      <section className="header">
        <h1 className="page-title">Users Management</h1>
        <Button variation="primary" onClick={addUser}>
          Add User
        </Button>
      </section>
      {loading === "PENDING" && <PageLoader message="Fetching users" />}
      {loading === "FAILED" && (
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}
      {loading === "DONE" && (
        <RenderUsers users={users} headers={userTableHeaders} />
      )}

      <Modal
        show={showModal}
        onHide={() => closeAddUser()}
        size="lg"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddUser token={token} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
