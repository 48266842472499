import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Group, Role } from "../interfaces/auth.interface";

export interface AuthenticationState {
  loading: "IDLE" | "LOADING" | "DONE";
  tableau_token: string | null;
  portal_token: string | null;
  error: string | null;
  loadingRoles: "IDLE" | "LOADING" | "DONE";
  roleError: string | null;
  appRoles: Role[];
  appGroups: Group[];
}

export const fetchTokens = createAsyncThunk(
  "fetchToken",
  async (payload: { email: string; token: string }) => {
    const response = await fetch(process.env.REACT_APP_API_ENDPOINT as string, {
      method: "POST",
      body: JSON.stringify({ email: payload.email }),
      headers: { Authorization: payload.token },
    });
    return response.json();
  }
);

export const fetchRoles = createAsyncThunk(
  "fetchRoles",
  async (payload: { token: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PFS_BASE_URL}/auth/roles`,
      {
        method: "GET",
        headers: { Authorization: payload.token },
      }
    );
    return response.json();
  }
);

const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    loading: "IDLE",
    tableau_token: null,
    portal_token: null,
    error: null,
    loadingRoles: "IDLE",
    roleError: null,
    appRoles: [],
    appGroups: [],
  } as AuthenticationState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTokens.pending, (state) => {
        state.loading = "LOADING";
        state.error = null;
        state.portal_token = null;
        state.tableau_token = null;
      })
      .addCase(fetchTokens.rejected, (state, action) => {
        state.loading = "DONE";
        state.error = action.error.message ?? null;
        state.portal_token = null;
        state.tableau_token = null;
      })
      .addCase(fetchTokens.fulfilled, (state, action) => {
        state.error = null;
        state.portal_token = action.payload.portal_token;
        state.tableau_token = action.payload.tableau_token;
        state.loading = "DONE";
      })
      .addCase(fetchRoles.pending, (state) => {
        state.loadingRoles = "LOADING";
        state.roleError = null;
        state.appRoles = [];
        state.appGroups = [];
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.loadingRoles = "DONE";
        state.roleError = action.error.message ?? null;
        state.appRoles = [];
        state.appGroups = [];
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roleError = null;
        state.appGroups = action.payload.groups;
        state.appRoles = action.payload.roles;
        state.loadingRoles = "DONE";
      });
  },
});

export const reducer = authenticationSlice.reducer;
