import React, { useEffect } from "react";
import "./SecondHeader.scss";
import { Link, NavLink } from "react-router-dom";
import { SelectField } from "@aws-amplify/ui-react";
import { useAppDispatch, useTypedSelector } from "../../../store/app.store.tsx";
import {
  fetchFacilities,
  setSelectedFacility,
} from "../../../store/facilities.reducer.tsx";
import { Facility } from "../../../interfaces/facility.interface.tsx";

export interface SecondHeaderProps {
  updateFacility: (val: string) => void;
  email: string;
  token: string;
}

export const SecondHeader = (props: SecondHeaderProps) => {
  const { email, token } = props;
  const dispatch = useAppDispatch();
  const facilities: Facility[] = useTypedSelector(
    (state) => state.facility.facilities
  ) as Facility[];
  const loading = useTypedSelector((state) => state.facility.loadingFacilities);

  useEffect(() => {
    dispatch(fetchFacilities({ email, token }));
  }, [dispatch, email, token]);

  const isActiveLink = (url: string): boolean => {
    return window.location.href.endsWith(url);
  };

  if (loading) {
    return null;
  }

  return (
    <div className="second-header">
      <Link to="/home" className="header-link">
        Home
      </Link>
      <nav>
        <ul>
          <li>
            <NavLink
              to="/dashboard"
              className={
                isActiveLink("/dashboard")
                  ? "header-link active-link"
                  : "header-link"
              }
            >
              Dashboards
            </NavLink>
          </li>
          {facilities && facilities.length > 0 && (
            <li>
              <SelectField
                label=""
                className="facility-select"
                onChange={(evt) =>
                  dispatch(setSelectedFacility(evt.target.value))
                }
              >
                {facilities.map((facility: Facility, index: number) => (
                  <option value={facility.site} key={`facility-${index}`}>
                    {facility.site}
                  </option>
                ))}
              </SelectField>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};
