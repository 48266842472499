import React, { useEffect } from "react";

import { Group } from "../../models/user";

import "./userGroupsForm.scss";
import {
  useAppDispatch,
  useTypedSelector,
} from "../../../../store/app.store.tsx";
import {
  UpdateUserGroups,
  fetchUserGroups,
  setUserGroup,
} from "../../../../store/user.reducer.tsx";
import {
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";

export const UserGroupsForm = (props: {
  groups: Group[];
  token: string;
  backButtonHandler: () => void;
}) => {
  const dispatch = useAppDispatch();
  const currentUser = useTypedSelector((state) => state.user.currentUser);
  const loading = useTypedSelector((state) => state.user.fetchingUserGroups);
  const updating = useTypedSelector((state) => state.user.updatingGroup);

  useEffect(() => {
    if (loading === "IDLE" && currentUser?.userInfo.user_id) {
      dispatch(
        fetchUserGroups({
          token: props.token,
          user_id: currentUser?.userInfo.user_id.toString(),
        })
      );
    }
  }, [currentUser?.userInfo.user_id, dispatch, loading, props.token]);

  const onSubmit = () => {
    if (currentUser.userInfo.user_id) {
      dispatch(
        UpdateUserGroups({
          token: props.token,
          user_id: currentUser.userInfo.user_id,
          groups: currentUser.userGroups,
        })
      );
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    dispatch(setUserGroup(value));

    console.log(value);
  };

  return (
    <form className="form">
      <h3>User Groups</h3>
      <FormControl className="field">
        <InputLabel id="demo-multiple-chip-label">User Groups</InputLabel>
        <Select
          fullWidth
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={currentUser?.userGroups ?? []}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        >
          {props.groups.map((group) => (
            <MenuItem key={`option-${group.group_id}`} value={group.group_id}>
              {group.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="button-container">
        <Button
          variant="contained"
          disabled={updating}
          onClick={() => onSubmit()}
        >
          Update
        </Button>
      </div>
    </form>
  );
};
